import {
	AuthError,
	createUserWithEmailAndPassword,
	getAuth,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut, 
	confirmPasswordReset
} from 'firebase/auth';
import React, { createContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import { AuthContextInterface } from '../../context/AuthContextInterface';
import { resetUser } from '../../utils/resetUser';
import store from 'src/store/store';
import { app } from 'src/services/firebase/firebase';

export const FirebaseAuthContext = createContext<AuthContextInterface>({
	user: null,
	loading: false,
	error: undefined,
	isAuthenticated: false,
	loginWithEmailAndPassword: (email: string, password: string) =>
		new Promise((resolve) => { }),
	register: (email: string, password: string) => new Promise((resolve) => { }),
	logout: () => new Promise((resolve) => { }),
	verifyEmail: () => new Promise((resolve) => { }),
	resetPassword: (email: string) => new Promise((resolve) => { }),
	emailVerified: false,
	authHook: undefined,
	setNewPassword: (oobCode: string, newPassword: string) => new Promise((resolve) => { })
});

interface AuthProviderProps {
	children: React.ReactNode;
}

export const FirebaseAuthProvider: React.FC<AuthProviderProps> = ({
	children,
}) => {
	const auth = getAuth(app);
	const [user, loading, error] = useAuthState(auth);

	const loginWithEmailAndPassword = async (email: string, password: string) => {
		try {
			const userCredential = await signInWithEmailAndPassword(
				auth,
				email,
				password
			);

			const idTokenResult = await userCredential.user.getIdTokenResult();

			const allowedSites = idTokenResult?.claims.allowedSites as string[];

			if (allowedSites !== undefined) {
				allowedSites.map((site) => site.toLowerCase());

				let redirectUrl = '';

				if (allowedSites.includes('tms')) {
					redirectUrl = `${process.env.REACT_APP_REDIRECT_TMS as string
						}?idToken=${encodeURIComponent(idTokenResult?.token)}`;
				} else if (allowedSites.includes('ca')) {
					redirectUrl = `${process.env.REACT_APP_REDIRECT_CA as string
						}?idToken=${encodeURIComponent(idTokenResult?.token)}`;
				} else if (allowedSites.includes('4u')) {
					redirectUrl = `${process.env.REACT_APP_REDIRECT_4U as string
						}?idToken=${encodeURIComponent(idTokenResult?.token)}`;
				} else {
					console.error(`Allowed Sites not recognised: "${allowedSites}"`);
					toast.error('User does not have access to any InteliGro platforms');
				}

				window.location.href = redirectUrl;
			} else {
				console.error('Allowed Sites not set');
				toast.error('User does not have access to any InteliGro platforms');
			}
		} catch (error: any) {
			handleError(error);
		}
	};

	const register = async (email: string, password: string) => {
		try {
			await createUserWithEmailAndPassword(auth, email, password);
		} catch (error: any) {
			handleError(error);
		}
	};

	const logout = async () => {
		try {
			logoutFirebase();
		} catch (error: any) {
			handleError(error);
		}
	};

	const verifyEmail = async () => {
		const currentUser = auth.currentUser;
		if (currentUser) {
			try {
				await sendEmailVerification(currentUser);
			} catch (error: any) {
				handleError(error);
			}
		}
	};

	const resetPassword = async (email: string) => {
		try {
			// This method does not throw an error when there's no user account with the given email address
			await sendPasswordResetEmail(auth, email);
		} catch (error: any) {
			handleError(error);
		}
	};

	const setNewPassword = async (oobCode: string, newPassword: string) => {
		try {
			
			await confirmPasswordReset(auth, oobCode, newPassword);

		} catch (error: any) {
			handleError(error);
		}
	};

	const handleError = (error: AuthError) => {
		toast.error('Error Authenticating');
		console.log('Authentication Error:', error);
	};

	const emailVerified = auth.currentUser
		? auth.currentUser.emailVerified
		: false;

	const isAuthenticated = user ? true : false;

	const authHook = useAuthState;

	return (
		<FirebaseAuthContext.Provider
			value={{
				isAuthenticated,
				user,
				loading,
				error,
				loginWithEmailAndPassword,
				register,
				logout,
				verifyEmail,
				resetPassword,
				emailVerified,
				authHook,
				setNewPassword
			}}>
			{children}
		</FirebaseAuthContext.Provider>
	);
};

export const refreshFirebaseToken = async () => {
	try {
		const auth = getAuth();
		const currentUser = auth.currentUser;
		if (!currentUser) {
			throw new Error('Cannot get new token');
		}
		return {
			accessToken: await currentUser?.getIdToken(true),
			refreshToken: currentUser?.refreshToken,
		};
	} catch (error) {
		return {
			refreshToken: null,
			accessToken: null,
		};
	}
};

export const logoutFirebase = async () => {
	const auth = getAuth();
	await signOut(auth);
	store.dispatch(resetUser());
	window.location.href = '/auth/login';
};
